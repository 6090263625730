.desktop-app {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  margin-left: 4%;
  width: 10%;
  height: 100vh;
  color: white;
  text-align: center;
}

.desktop-logo {
  width: 4rem;
}

.desktop-app div {
  margin-bottom: 40%;
}

.desktop-app h2 {
  margin-top: 2%;
  text-shadow: 2px 0 black, 0 2px black, 2px 0 black, 0 2px black;
}

.logo-box {
  width: 5rem;
}
