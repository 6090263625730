body {
  background-image: url("wallpaper.png");
  background-color: rgb(172, 156, 156);
  background-blend-mode: multiply;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  /* font-family: "Press Start 2P", cursive !important; */
}

.myAppbar {
  top: auto !important;
  bottom: 0;
  z-index: 5;
}

.windows-logo {
  width: 30px;
  margin-right: 5px;
}

.window-btn {
  width: 40%;
}
