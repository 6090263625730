.window-div {
  position: absolute;
  z-index: 2;
  left: 20%;
  top: 2%;
}

.max-window-div {
  left: 0;
  top: 0;
}

.window-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.close-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -1px;
  transform: rotateZ(45deg);
  position: relative;
}
.window {
  width: 70vw;
  min-height: 70vh;
  position: absolute;
  resize: both;
  overflow: auto;
}

.max-window {
  width: 100vw;
  min-height: -webkit-fill-available;
}
.window:nth-child(2) {
  margin: 2rem;
}
.footer {
  display: block;
  margin: 0.25rem;
  height: 31px;
  line-height: 31px;
  padding-left: 0.25rem;
}

.my-content {
  background: #fff;
  box-shadow: inset -1px -1px #fff, inset 1px 1px grey, inset -2px -2px #dfdfdf, inset 2px 2px #0a0a0a;
  display: block;
  margin: 0;
  padding: 10px;
  height: 60vh;
  overflow: scroll;
}

.max-my-content {
  height: 75vh;
}

.title-bar-text {
  font-size: 1rem;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .window {
    width: 90vw;
    min-height: 80vh;
  }
  .window-div {
    left: 8%;
    top: 2%;
  }
  .max-my-content {
    height: 75vh;
  }
  .max-window {
    width: 100vw;
    min-height: -webkit-fill-available;
  }
  .max-window-div {
    left: 0;
    top: 0;
  }
}
