.market-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 5px;
  font-family: "Times New Roman", Times, serif;
  margin-left: 10%;
}

.market-options-box {
  margin-bottom: 3%;
}

.no-desktop {
  display: none;
}

.handmade {
  width: 50%;
}

.ebay-market {
  width: 10vw;
}

.categories {
  padding: 8px;
  font-size: 1rem;
  background: #f8ca02;
  text-align: center;
  width: 50%;
  color: #000;
}

.cata-txt {
  font-size: 2vh;
  color: blue;
}

.dummy-qty {
  color: rgb(211, 186, 186);
}

.market-items {
  width: 15vw;
  height: 15vw;
}

.items-box {
  margin: 5% 0;
  width: 17vw;
  padding: 5px;
  border: 2px solid white;
  text-align: center;
}
.items-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-right: 20px;
}

.nwinsworld {
  width: 18vw;
}
@media only screen and (max-width: 768px) {
  .container {
    grid-template-columns: 60px 3fr;
    grid-template-rows: auto auto;
    margin: 0;
  }
  .items-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .market-options {
    width: 25%;
  }
  .market-options-box {
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
    margin-top: 5%;
  }
  .ebay-market {
    width: 100%;
  }
  .cata-txt {
    font-size: 0.4rem;
  }
  .handmade {
    width: 100%;
  }
  .market-items {
    width: 22vw;
    height: 22vw;
  }
  .categories {
    padding: 0;
    font-size: 0.3rem;
    width: 50%;
  }
  .items-box {
    margin: 0;
    width: 100%;
    padding: 2px;
    border: 2px solid white;
    text-align: center;
  }
  .nwinsworld {
    width: 50vw;
  }
  .no-mobile {
    display: none;
  }
  .epic {
    width: 20vw;
  }
  .no-desktop {
    display: block;
  }
}
