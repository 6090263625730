.pfp {
  width: 180px;
}

.name {
  font-size: 1.75rem;
  color: #5573b1;
  font-family: "Roboto";
}

.about-bg-pic {
  height: 15vh;
  background: #4267b2;
  font-family: "Roboto" !important;
  font-size: 2rem;
  color: white;
}

.about-content {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 1%;
}

.about-section {
  font-family: "Roboto";
  font-size: 1.3rem;
  font-weight: 600;
}

.user-post {
  /* width: 500px; */
  margin-top: 1%;
  padding-top: 2%;
  height: 12vh;
  background-color: #e6e6e6;
  border: 3px dashed #aebed6;
  text-align: center;
}

.all-posts {
  width: 100%;
  margin-right: 3%;
}

.post-button {
  background: #4267b2;
  color: white;
}

.user-post-func {
  display: flex;
  justify-content: space-between;

  padding: 2% 7%;
}

.pfp-post {
  width: 50px;
  height: 50px;
}

.post-pic {
  width: 120px;
}

.post-owner {
  display: flex;
  justify-content: row;
}

.postings {
  margin-top: 2%;
  margin-bottom: 3vh;
  background-color: #e6e6e6;
  padding: 15px;
  border: 3px dashed #aebed6;
}

.description {
  margin-top: 3%;
}

.info-box {
  background-color: #e6e6e6;
  border: 3px dashed #aebed6;

  padding: 10px;
}

.post-pic-big {
  width: 200px;
}

.post-pic-md {
  width: 130px;
}

.inside-info {
  height: 80%;
  padding: 10px;
  background-color: white;
}

.info-title {
  font-weight: bold;
}

.info-text {
  font-size: 0.9rem;
}

.info-title-box {
  margin: 5% 0;
}

@media (max-width: 767px) {
  .about-content {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-left: 2%;
  }

  .user-post-func {
    font-size: 3vw;
  }

  .all-posts {
    margin: 10% 0;
  }
}
