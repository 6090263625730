.project-greeting {
  font-family: "Times New Roman", Times, serif;
  font-size: 5vw;
  font-weight: bold;
  color: yellow;
  background-color: blue;
  text-align: center;
}

.project-table-content {
  font-family: "Comic Neue", cursive;
  font-weight: 800;
  font-size: 2.5rem;
  color: yellow;
  text-decoration: blue underline;
  /* cursor: pointer; */
  text-align: center;
}

.project-1 {
  background-image: url("bg.png");
  margin: 10% auto 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 90%;
  box-shadow: 0 0 30px 19px blue;
  border-radius: 40px;
}

.github {
  width: 20px;
}

.project-1-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 5vw;
  font-weight: bold;
  color: yellow;
}

.zotnfound-logo-img {
  width: 10%;
}

.project-description {
  font-family: "Times New Roman", Times, serif;
  font-size: 1vw;
  color: white;
  padding: 3%;
  border-radius: 20px;
  width: 70%;
  padding: 20px;
  background-color: rgb(9, 9, 99);
  box-shadow: 0 0 30px 10px #2358f7;
  text-align: center;
}

.project-1-main {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.carousel-root {
  display: flex;
  justify-content: center;
}

.project-2 {
  background-image: url("bg2.png");
  margin: 5% auto 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 90%;
  box-shadow: 0 0 30px 19px rgb(216, 216, 140);
  border-radius: 40px;
}

.project-3 {
  background-image: url("bg5.png");
  margin: 5% auto 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 90%;
  box-shadow: 0 0 30px 19px rgb(153, 161, 246);
  border-radius: 40px;
}

.project-3-description {
  font-family: "Times New Roman", Times, serif;
  font-size: 1.3vw;
  color: white;
  padding: 3%;
  border-radius: 20px;
  width: 60%;
  background-color: rgb(74, 55, 122);
  text-align: center;
  margin: 5% 0;
}

.project-0 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("bg0.jpg");

  margin: 5% auto 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 90%;
  box-shadow: 0 0 30px 19px rgb(161, 161, 94);
  border-radius: 40px;
}

.project-3-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 5vw;
  font-weight: bold;
  color: yellow;
}

.project-thumbnail {
  width: 70%;
  border-radius: 30px;
  box-shadow: 0 0 30px 15px #2358f7;
}

.project-2-color {
  box-shadow: 0 0 20px 3px rgb(216, 216, 140);
}
.github-btn {
  background-color: darkred;
  padding: 1vw;
  /* width: 50%; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 2%;
  font-size: 1rem;
  border: 2px white solid;
}

.uci-btn {
  background-color: darkblue;
  padding: 1vw;
  /* width: 50%; */
  color: yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-left: 2%;
  border: 2px yellow solid;
  font-size: 1rem;
}

.devpost-btn {
  background-color: rgb(244, 244, 244);
  padding: 1vw;
  /* width: 50%; */
  color: navy;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 2%;
  border: 2px blue solid;
  font-size: 1rem;
}

.btn-div {
  margin-top: 8%;
  display: flex;
  justify-content: center;
}

.website-btn {
  background-color: rgb(95, 89, 89);
  padding: 1vw;
  /* width: 100%; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-left: 5%;
  font-size: 1rem;
  border: 2px white solid;
}

.project-4 {
  background-image: url("bg4.png");
}

.more-projects {
  background-image: url("bg5.png");
  margin: 5% auto 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 90%;
  box-shadow: 0 0 30px 19px rgb(153, 161, 246);
  border-radius: 40px;
}

.mp-img {
  width: 20vw;
}

.project-5 {
  background-image: url("bg3.jpg");
}

.description-img {
  width: 12vh;
}

.ttt-img {
  border-radius: 20px;
}

.zelp-img {
  width: 18vh;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
}

.comp {
  width: 10%;
}

.havefun-logo {
  border-radius: 20px;
}

.award-text {
  font-family: "Comic Sans MS", "Comic Neue", sans-serif;
  font-size: 1.5rem;
}
/* MOBILE */
@media (max-width: 767px) {
  .project-greeting {
    font-size: 6.2vw;
  }

  .project-table-content {
    font-size: 7vw;
    font-weight: 100;
    margin-bottom: 10%;
  }
  .seal {
    display: none;
  }

  .bubble {
    display: none;
  }

  .proj-text {
    width: 90%;
    margin: 5% 0;
  }
  .project-0 {
    flex-direction: column;
  }
  .project-1 {
    flex-direction: column;
  }
  .project-2 {
    flex-direction: column;
  }
  .project-3 {
    flex-direction: column;
  }

  .project-thumbnail {
    width: 100%;
  }

  .zelp-text {
    width: 70%;
  }

  .project-description {
    font-size: 4vw;
  }
  .more-proj {
    width: 100% !important;
    margin: 5% 0 !important;
  }
}
